.course{
    max-width: 250px;
    min-width: 150px;
    transition: box-shadow .2s ease-in-out;
    cursor: pointer;
}

.course:hover{
    box-shadow: rgba(0,0,0,0.2) 0px 6px 12px 0px, rgba(0,0,0,0.1) 0px 0px 0px 1px;
}

.fakeCourseCard{
    height: 100%;
    width: 100%;
    /* background-color: #537272 !important; */
}

.fakeCourseCardBtn{
    display: block;
    margin-left: auto;
    margin-right: auto;
}