.coursesPage{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.coursesGrid{
    width: 100%;
}

/* .courseCardImg{

}*/

.cardBody{
    /* overflow: hidden;
    mask-image: linear-gradient(180deg, #000 60%, transparent); */
    /* background-color: #537272 !important;
    border-color: #537272 !important; */
} 

.dateText{
    font-size: 0.7em;
}
