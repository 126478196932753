:root{
    --chooseProfilePicWidth: 50px;
}

.blockCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.width100 {
    width: 100%;
}

.textCenter {
    text-align: center;
}

.chooseProfilePic{
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    font-size: var(--chooseProfilePicWidth);
    cursor: pointer;
}

.chooseProfilePic+img{
    cursor: pointer;
}

.chooseProfilePic:checked+img{
    /* background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 4px 6px rgba(255, 0, 0, 0.5); */
    outline: 2px solid #f00;
}

.activeProfilePic{
    /* background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); */
    outline: 4px solid rgba(255, 255, 255, 0.5);
}

.profilePicImg {
    width: var(--chooseProfilePicWidth);
    height: auto;
    margin: 3px;
}

.choosenPicImgLive {
    margin-top: 1em;
    margin-bottom: 1em;
    width: auto;
    max-width: 120px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}