.navMargin {
    margin-bottom: 3vh;
}

.navImg {
    width: auto;
    height: 4vh;
    margin-right: 5px;
}

.logo{
    width: auto;
    height: 4vh;
    margin-right: 1em;
}

.space{
    margin-right: 10px;
}