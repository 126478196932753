:root{
  --dark: #1a1a1d;
  --mid: #2d3142;
  --midlight: #bfc0c0;
  --light: #fff;
  --accent: #D3AC2B;
  --accentlight: #D3AC2B;
  --accentsecondary: #17572f;
  --bg: #bdbdbd;
  --navbar: #0f0802;
  --btnbase: #bd782d;
  --btnhover: #62513f;
  --textcolor: #efeee9;
}
/* 
*{
  color: var(--textcolor) !important;
} */

body {
  margin: 0;
  background: var(--bg);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .active{
  background-color: rgba(255, 0, 0, 0.3);
  border-radius: 12px;
} */