.form-check-input {
  margin-top: 0em;
  cursor: pointer;
}

.btn{
  background-color: var(--btnbase);
  border-color: var(--btnbase);
}

.btn:hover{
  background-color: var(--btnhover);
  border-color: var(--btnhover);
}

.bg-dark{
  background-color: var(--navbar) !important;
}